var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "header-class": "header-class-modal-doc-package",
        title: _vm.FormMSG(3, "Change email"),
        "ok-title": _vm.FormMSG(1, "Save"),
        "ok-disabled": _vm.saveDisabled,
        "ok-variant": "success",
        "cancel-title": _vm.FormMSG(2, "Cancel"),
        "cancel-variant": "primary",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "modal-class": "mui-animation",
        fade: false,
      },
      on: { ok: _vm.saveClick, hidden: _vm.emitCloseEvent },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c("b-alert", { attrs: { variant: "warning", show: "" } }, [
        _vm._v(
          "\n\t\t" +
            _vm._s(
              _vm.FormMSG(
                5,
                "If you change your email, the old email will no longer be valid to send notifications from TheGreenShot"
              )
            ) +
            "\n\t"
        ),
      ]),
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "b-col",
            {
              class: _vm.$screen.width <= 567 ? "mb-3" : "",
              attrs: { cols: _vm.$screen.width <= 567 ? 12 : 8 },
            },
            [
              _vm.$screen.width > 567
                ? _c(
                    "b-input-group",
                    { attrs: { prepend: "@" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          type: "text",
                          id: "email",
                          state: _vm.stateError.email.global,
                          placeholder: _vm.FormMSG(4, "Enter new mail"),
                          "aria-describedby": "input-email-feedback",
                        },
                        on: { input: _vm.handleInputNewEmail },
                        model: {
                          value: _vm.newEmail,
                          callback: function ($$v) {
                            _vm.newEmail = $$v
                          },
                          expression: "newEmail",
                        },
                      }),
                      _vm.stateError.email.sameOf
                        ? _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "input-email-feedback" } },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.FormMSG(
                                      6,
                                      "The email must be different from your old email"
                                    )
                                  ) +
                                  "\n\t\t\t\t"
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.stateError.email.invalid
                        ? _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "input-email-feedback" } },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(7, "Email invalid")) +
                                  "\n\t\t\t\t"
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$screen.width <= 567
                ? _c(
                    "b-input-group",
                    { attrs: { append: "@" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          type: "text",
                          id: "email",
                          state: _vm.stateError.email.global,
                          placeholder: _vm.FormMSG(4, "Enter new mail"),
                          "aria-describedby": "input-email-feedback",
                        },
                        on: { input: _vm.handleInputNewEmail },
                        model: {
                          value: _vm.newEmail,
                          callback: function ($$v) {
                            _vm.newEmail = $$v
                          },
                          expression: "newEmail",
                        },
                      }),
                      _vm.stateError.email.sameOf
                        ? _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "input-email-feedback" } },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.FormMSG(
                                      6,
                                      "The email must be different from your old email"
                                    )
                                  ) +
                                  "\n\t\t\t\t"
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.stateError.email.invalid
                        ? _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "input-email-feedback" } },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(7, "Email invalid")) +
                                  "\n\t\t\t\t"
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-col",
            {
              class: _vm.$screen.width <= 567 ? "mb-3" : "",
              attrs: { cols: _vm.$screen.width <= 567 ? 12 : 4 },
            },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "primary",
                    block: "",
                    disabled: _vm.disableVerifyEmail || _vm.loadingVerifyEmail,
                  },
                  on: { click: _vm.verifyEmail },
                },
                [
                  _vm.dataToCheck.emailCodeValidation !== 0 &&
                  !_vm.loadingVerifyEmail
                    ? _c("span", [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(9, "Resend code")) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                  !_vm.loadingVerifyEmail &&
                  _vm.dataToCheck.emailCodeValidation === 0
                    ? _c("span", [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(8, "Verify mail")) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                  _vm.loadingVerifyEmail
                    ? _c("span", [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(10, "Check email")) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.$screen.width < 576 && _vm.showVerificationMail
        ? _c("p", { staticClass: "mb-3" }, [
            _vm._v(
              "\n\t\t" +
                _vm._s(_vm.FormMSG(53, "Enter email verfication code")) +
                "\n\t"
            ),
          ])
        : _vm._e(),
      _vm.showVerificationMail
        ? _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c("b-col", { attrs: { cols: "8" } }, [
                _c(
                  "fieldset",
                  {
                    staticClass: "form-group",
                    attrs: {
                      horizontal: true,
                      id: "fieldset-email-validation",
                      "aria-labelledby": "aria-phone-validation",
                    },
                  },
                  [
                    _c("div", { staticClass: "form-row" }, [
                      _vm.$screen.width >= 576
                        ? _c(
                            "legend",
                            {
                              staticClass:
                                "col-8 bv-no-focus-ring col-form-label",
                              attrs: {
                                tabindex: "-1",
                                id: "aria-email-validation",
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.FormMSG(
                                      53,
                                      "Enter email verfication code"
                                    )
                                  ) +
                                  " :\n\t\t\t\t\t"
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c("b-form-input", {
                            attrs: {
                              state: _vm.stateError.emailCodeValidation,
                              placeholder: "ex: 123456",
                            },
                            on: { input: _vm.handleInputEmailCodeValidation },
                            model: {
                              value: _vm.emailCodeValidation,
                              callback: function ($$v) {
                                _vm.emailCodeValidation = $$v
                              },
                              expression: "emailCodeValidation",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]),
              _vm.stateError.emailCodeValidation !== null &&
              _vm.stateError.emailCodeValidation === false
                ? _c(
                    "b-col",
                    { staticClass: "pt-2", attrs: { cols: "4" } },
                    [_c("X", { attrs: { color: "#B11548", size: 20 } })],
                    1
                  )
                : _vm._e(),
              _vm.stateError.emailCodeValidation === true
                ? _c(
                    "b-col",
                    {
                      class: _vm.$screen.width < 576 ? "pt-2" : "pt-2",
                      attrs: { cols: "4" },
                    },
                    [_c("Check", { attrs: { color: "#28a745", size: 20 } })],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }