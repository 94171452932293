<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		v-model="isOpen"
		:title="FormMSG(3, 'Change email')"
		:ok-title="FormMSG(1, 'Save')"
		:ok-disabled="saveDisabled"
		ok-variant="success"
		:cancel-title="FormMSG(2, 'Cancel')"
		cancel-variant="primary"
		no-close-on-backdrop
		no-close-on-esc
		@ok="saveClick"
		@hidden="emitCloseEvent"
		modal-class="mui-animation"
		:fade="false"
	>
		<b-alert variant="warning" show>
			{{ FormMSG(5, 'If you change your email, the old email will no longer be valid to send notifications from TheGreenShot') }}
		</b-alert>
		<b-row class="mb-3">
			<b-col :cols="$screen.width <= 567 ? 12 : 8" :class="$screen.width <= 567 ? 'mb-3' : ''">
				<b-input-group v-if="$screen.width > 567" prepend="@">
					<b-form-input
						v-model="newEmail"
						type="text"
						id="email"
						:state="stateError.email.global"
						:placeholder="FormMSG(4, 'Enter new mail')"
						aria-describedby="input-email-feedback"
						@input="handleInputNewEmail"
					/>
					<b-form-invalid-feedback v-if="stateError.email.sameOf" id="input-email-feedback">
						{{ FormMSG(6, 'The email must be different from your old email') }}
					</b-form-invalid-feedback>
					<b-form-invalid-feedback v-if="stateError.email.invalid" id="input-email-feedback">
						{{ FormMSG(7, 'Email invalid') }}
					</b-form-invalid-feedback>
				</b-input-group>
				<b-input-group v-if="$screen.width <= 567" append="@">
					<b-form-input
						v-model="newEmail"
						type="text"
						id="email"
						:state="stateError.email.global"
						:placeholder="FormMSG(4, 'Enter new mail')"
						aria-describedby="input-email-feedback"
						@input="handleInputNewEmail"
					/>
					<b-form-invalid-feedback v-if="stateError.email.sameOf" id="input-email-feedback">
						{{ FormMSG(6, 'The email must be different from your old email') }}
					</b-form-invalid-feedback>
					<b-form-invalid-feedback v-if="stateError.email.invalid" id="input-email-feedback">
						{{ FormMSG(7, 'Email invalid') }}
					</b-form-invalid-feedback>
				</b-input-group>
			</b-col>
			<b-col :cols="$screen.width <= 567 ? 12 : 4" :class="$screen.width <= 567 ? 'mb-3' : ''">
				<b-button variant="primary" block :disabled="disableVerifyEmail || loadingVerifyEmail" @click="verifyEmail">
					<span v-if="dataToCheck.emailCodeValidation !== 0 && !loadingVerifyEmail">
						{{ FormMSG(9, 'Resend code') }}
					</span>
					<span v-if="!loadingVerifyEmail && dataToCheck.emailCodeValidation === 0">
						{{ FormMSG(8, 'Verify mail') }}
					</span>
					<span v-if="loadingVerifyEmail">
						{{ FormMSG(10, 'Check email') }}
					</span>
				</b-button>
			</b-col>
		</b-row>
		<p v-if="$screen.width < 576 && showVerificationMail" class="mb-3">
			{{ FormMSG(53, 'Enter email verfication code') }}
		</p>
		<b-row v-if="showVerificationMail" class="mb-3">
			<b-col cols="8">
				<fieldset class="form-group" :horizontal="true" id="fieldset-email-validation" aria-labelledby="aria-phone-validation">
					<div class="form-row">
						<legend v-if="$screen.width >= 576" tabindex="-1" class="col-8 bv-no-focus-ring col-form-label" id="aria-email-validation">
							{{ FormMSG(53, 'Enter email verfication code') }} :
						</legend>
						<div class="col">
							<b-form-input
								v-model="emailCodeValidation"
								:state="stateError.emailCodeValidation"
								placeholder="ex: 123456"
								@input="handleInputEmailCodeValidation"
							/>
						</div>
					</div>
				</fieldset>
			</b-col>
			<b-col v-if="stateError.emailCodeValidation !== null && stateError.emailCodeValidation === false" cols="4" class="pt-2">
				<X color="#B11548" :size="20" />
			</b-col>
			<b-col v-if="stateError.emailCodeValidation === true" cols="4" :class="$screen.width < 576 ? 'pt-2' : 'pt-2'">
				<Check color="#28a745" :size="20" />
			</b-col>
		</b-row>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { emailValidationCode } from '@/cruds/registration.crud';
import { X, Check } from 'lucide-vue';
import { store } from '@/store';

export default {
	name: 'ChangeMailModal',

	components: {
		X,
		Check
	},

	mixins: [languageMessages, GlobalMixin],

	props: {
		open: { type: Boolean, default: false },
		profile: { type: Object, default: () => ({}) }
	},

	data() {
		return {
			newEmail: '',
			stateError: {
				email: {
					global: null,
					sameOf: null,
					invalid: null
				},
				emailCodeValidation: null
			},
			emailCodeValidation: '',
			loadingVerifyEmail: false,
			dataToCheck: {
				email: '',
				emailCodeValidation: 0
			}
		};
	},

	computed: {
		saveDisabled() {
			return !(
				this.newEmail.length !== 0 &&
				this.dataToCheck.email === this.newEmail &&
				parseInt(this.dataToCheck.emailCodeValidation) === parseInt(this.emailCodeValidation)
			);
		},
		disableVerifyEmail() {
			const emailfilter = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
			if (!emailfilter.test(this.newEmail) || !this.stateError.email.global || this.stateError.email.global === null) {
				return true;
			}
			return false;
		},
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		},
		showVerificationMail() {
			if (this.dataToCheck.emailCodeValidation !== 0) {
				return true;
			}
			return false;
		}
	},

	methods: {
		initData() {
			this.newEmail = '';
			this.stateError = {
				email: {
					global: null,
					sameOf: null,
					invalid: null
				},
				emailCodeValidation: null
			};
			this.emailCodeValidation = '';
			this.dataToCheck = {
				email: '',
				emailCodeValidation: 0
			};
		},
		saveClick() {
			this.$emit('change-mail-modal:save', {
				newEmail: this.newEmail
			});
			this.initData();
		},
		handleInputEmailCodeValidation(value) {
			if (parseInt(this.dataToCheck.emailCodeValidation) === parseInt(value)) {
				this.stateError.emailCodeValidation = true;
			} else {
				this.stateError.emailCodeValidation = false;
			}
		},
		async verifyEmail() {
			this.loadingVerifyEmail = true;
			const email = this.newEmail;
			const result = await emailValidationCode({
				userName: this.profile.name,
				userFirstName: this.profile.firstName,
				email: email,
				language: store.appLanguage() === 1 ? 1 : 0
			});
			this.dataToCheck.emailCodeValidation = result.emailValidationCode;
			this.dataToCheck.email = email;
			this.emailCodeValidation = '';
			this.stateError.emailCodeValidation = null;
			this.loadingVerifyEmail = false;

			if (this.$screen.width <= 567) {
				this.createToastForMobile(this.FormMSG(60, 'An e-mail containing a validation code has just been sent to') + ' ' + email, '', '');
			} else {
				this.$toast({
					message: this.FormMSG(60, 'An e-mail containing a validation code has just been sent to') + ' ' + email,
					title: this.FormMSG(368, 'Success'),
					variant: 'success'
				});
			}
		},
		handleInputNewEmail(value) {
			if (value !== this.profile.email) {
				const emailfilter = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
				if (!emailfilter.test(value)) {
					this.stateError.email.global = false;
					this.stateError.email.sameOf = null;
					this.stateError.email.invalid = true;
				} else {
					this.stateError.email.global = true;
					this.stateError.email.sameOf = null;
					this.stateError.email.invalid = null;
				}
			} else {
				this.stateError.email.global = false;
				this.stateError.email.sameOf = true;
				this.stateError.email.invalid = null;
			}
		},
		emitCloseEvent() {
			this.initData();
			this.$emit('modal:close');
		}
	}
};
</script>

<style lang="scss" scoped>
.input-group-text {
	border-radius: 8px 0px 0px 8px !important;
}
</style>
